<template>
    <!-- External Filter -->
    <div v-if="!efect" class="block md:flex w-full px-2 mt-1 mb-2">
      <div style="width: 100%; height: 100%;">
            <div class="custom-container">
                <div class="custom-header">
                    <div v-for="(row, index) in fields" :key="index" class="grid md:flex mx-1 flex">
                        <input v-if="row.type != 'select'" :type="row.type" v-model="fields[index].value" class="w-full border rounded px-2 py-1 mx-1" name="filter" :placeholder="row.label" :id="row.name" v-on:change="externalFilterChanged(index)">

                        <label v-if="row.type == 'select'" to="filter" class="w-24 example-header mr-1 text-cherryColor text-sm">Referido por:</label>
                        <select v-if="row.type == 'select'" name="filter" :id="row.name" v-model="fields[index].value" @change="externalFilterChanged(index)"  class="w-72 bg-transparent text-cherryColor focus:bg-blue-700 rounded-md border-2 border-cherryColor cursor-pointer">
                            <option value=" " selected></option>
                            <option :value="opt.value" v-show="typeof opt != undefined" v-for="(opt, index) in row.values" :key="index"> {{opt.name.toString().toUpperCase()}} </option>
                        </select>
                    </div>
                    
                </div>
            </div>
      </div>
    </div>
</template>

<script>
    import { onMounted, ref } from 'vue';
    export default {
        props: {
            fields: {
                type: Array,
                default: () => [] 
            }
        },
        setup(props, {emit}){
            const fields = ref([]);

            onMounted(async() => {
               await props.fields.forEach(element => {
            
                const values = element.values.filter(x => x !== undefined)
            
                fields.value.push({
                    "type": element.type,
                    "name": element.name,
                    "label": element.label,
                    "value": "",
                    "values": values
                });
               });
            })

            const externalFilterChanged = (index) => {
                emit("table-filter", fields.value)
            }

            return {
                externalFilterChanged,
                fields
            }
        }
    }
    
</script>

<style scoped>
.custom-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

</style>
