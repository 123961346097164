<!-- Use preprocessors via the lang attribute! e.g. <template lang="pug"> -->
<template>
  <div :class="classContainer">
    <div v-if="efect" class="h-full">
        <div class="animate-pulse flex space-x-4 rounded-md h-5 my-5 w-auto mx-5">
          <div class="flex-1 space-y-6 py-1">
            <div class="space-y-3">
              <div class="grid grid-cols-3 gap-4">
                <div class="h-5 bg-gray-100 rounded col-span-2"></div>
                <div class="h-5 bg-gray-100 rounded col-span-1"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="animate-pulse rounded-md h-96 w-auto mx-5 bg-gray-100">
        </div>
    </div>

    <div v-if="!efect" class="block md:flex w-full px-2 mt-1 mb-2">
      <div v-show="rangeInput" class="flex w-full px-2 mt-1 mb-2 justify-start">
        <div class="example-header mr-5 text-cherryColor">
          Mostrando registros:
          <select v-on:change="onPageSizeChanged()" id="page-size" class="bg-transparent text-cherryColor focus:bg-blue-700 rounded-md border-2 border-cherryColor cursor-pointer">
            <option value="10" selected="">10</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
          </select>
        </div>
      </div>
      <div v-show="exportInput || checkInput.show" class="flex w-full px-2 mt-5 md:mt-1 mb-2 justify-start md:justify-end">
        <button v-show="exportInput" class="w-50 h-8 text-sm md:text-base flex text-cherryColor focus:bg-blue-700 px-2 md:px-10 py-0.5 rounded-md mr-2 border-2 border-cherryColor hover:bg-cherryColor hover:text-white" @click="onBtnExport()"> 
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 my-auto mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
          </svg>
          Exportar
        </button>
        <button v-show="checkInput.show" class="w-50 h-8 text-sm md:text-base flex text-cherryColor focus:bg-blue-700 px-2 md:px-10 py-0.5 rounded-md mr-2 border-2 border-cherryColor hover:bg-cherryColor hover:text-white" @click="getSelectedRows()" v-html="checkInput.text"> 
        </button>
      </div>
    </div>

    <TableFilter v-if="!efect && filters.length >0" :fields="filters" v-on:table-filter="changeFilter($event)" />

    <ag-grid-vue
    v-if="!efect"
    :class="{'ag-theme-alpine-custom my-5': withStyle, 'h-96': !withStyle}"
    class="ag-theme-alpine"
    :getRowClass="getRowClass"
    :rowClass="rowClass"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :pagination="true"
    :defaultColDef="defaultColDef"
    :rowSelection="rowSelection"
    :overlayLoadingTemplate="overlayLoadingTemplate"
    :overlayNoRowsTemplate="overlayNoRowsTemplate"
    :paginationPageSize="paginationPageSize"
    :localeText="localeText"
    :paginationNumberFormatter="paginationNumberFormatter"
    @grid-ready="onGridReady"
    :onSelectionChanged="onSelectionChanged"
    >
    </ag-grid-vue>
  </div>

  <div v-show="buttonDown.show" class="w-full">
    <button class="ml-auto mt-5 w-50 h-8 text-sm md:text-base flex text-white focus:bg-blue-700 px-2 md:px-10 py-0.5 rounded-md mr-2 bg-mustard border-2 border-mustard hover:bg-mustard hover:text-white" @click="clickButtonDown()" v-html="buttonDown.text"> 
    </button>
  </div>

  <slot class="mx-auto text-rigth">

  </slot>
</template>

<script>
import { toRef, ref, onMounted, watch} from "vue";
import Loading from "@/components/Loading/VueLoading.vue";
import "@/assets/css/tailwind.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3"; //tabla principal
import { AG_GRID_LOCALE_ES } from "@/assets/js/config_spanish.js"; // traduccion a español

import TableFilter from "@/components/Forms/Tables/TableFilter";
import moment from "moment";
import SwalAlertHelper from '@/helpers/swal-alert';

export default {
  name: "TableComponent",
  components: {
    AgGridVue,
    Loading,
    TableFilter,
  },
  props: {
    columns: {
      type: Array,
      default: [
        { headerName: "Column", field: "id" }
      ]
    },
    rows: {
      type: Array,
      default: [
        { id: "Default", text: ""},
      ]
    },
    withStyle: {
      type: Boolean,
      default: true
    },
    method: {
      type: Function,
      default() {
        return 'Default function'
      }
    },
    checkInput: {
      type: Object,
      default(){
        return {
          text: '',
          show: false
        }
      }
    },
    exportInput: {
      type: Boolean,
      default: () => false
    },
    rangeInput: {
      type: Boolean,
      default: () => false
    },
    filterInput: {
      type: Boolean,
      default: () => true
    },
    buttonDown: {
      type: Object,
      default(){
        return {
          text: '',
          show: false
        }
      }
    },
    classContainer: {
      type: String,
      default(){
        return ""
      }
    },
    filters: {
      type: Array,
      default(){
        return [];
      }
    }, 
    returnInputChecked: {
      type: Boolean,
      default: false
    },
    column_keys: {
      type: Array,
      default(){
        return [];
      }
    }
  },    
  setup(props, {emit}) {
    const columnKeys = toRef(props, 'column_keys');

    const getInputChecked = toRef(props, 'returnInputChecked');
    
    const defaultColDef = ref({ //Estas opciones se agregan a cada columna
        //Ordenamiento
        sortable: true,
        //Asigna el valor por defecto del ancho de la columna
        width: 200,
        //hace cada columna editable
        editable: false,
        //Agrega cada columna el campo para buscar
        filter: 'agTextColumnFilter',
        //habilita los filtros
        floatingFilter: true,
        //hace las columnas ajustables
        resizable: true,
        //ajusta la columna
        suppressSizeToFit: true
    });

    const overlayLoadingTemplate = ref(null);
    const overlayNoRowsTemplate = ref(null);
    
    const localeText = AG_GRID_LOCALE_ES;
    const rowSelection = ref("multiple");
    const efect = ref(true);
    const rowClass = "my-green-class";
    const rowsSelected = ref([]);
    const gridColumnApi = ref(null);
    const gridApi = ref(null);

    let paginationPageSize = ref(10);


    onMounted(async() => {
      setTimeout(() => {
        efect.value = false;        
      }, 1000);


      const filterStatus = await props.filterInput;
      if(!filterStatus){
        defaultColDef.value.floatingFilter = "";
        defaultColDef.value.filter = false;
      }
    });
    
    overlayLoadingTemplate.value = '<span class="ag-overlay-loading-center">Cargando información</span>';
    overlayNoRowsTemplate.value = '<span class="ag-overlay-loading-center">No hay registros para mostrar</span>';


    function showLoading(){
      gridApi.value.showLoadingOverlay();
    }

    function showNoRowsLoading(){
      gridApi.value.showNoRowsOverlay()
    }

    function hideLoading(){
      overlayLoadingTemplate.value = null;
      gridApi.value.hideOverlay();
    }

    watch(getInputChecked, (val) => {
      getSelectedRows();
      emit('rowsSelectedChanged', rowsSelected.value);
    });

    function onSelectionChanged() {
      const selectedNodes = gridApi.value.getSelectedNodes();
      const selectedData = selectedNodes.map( node => node.data);
      emit('rowsSelectedChanged', selectedData);
    }

    const paginationNumberFormatter = (params) => {
      return params.value.toLocaleString();
    };

    const getRowClass = params => {
        if (params.node.rowIndex % 2 === 0) {
            return 'my-shaded-effect';
        }
    };
    
    const onGridReady = params => {
      gridApi.value = params.api;
      gridColumnApi.value = params.columnApi;

      showLoading();
      if(props.rows.length > 0) {
        setTimeout(() => {
        hideLoading()
        }, 1000)
      }
      if(props.rows.length == 0) {
        showNoRowsLoading()
      }
    
    }

    const onBtnExport = () =>{

      const rowsSeletect = getSelectedRows().length;
      if(rowsSeletect==0) return SwalAlertHelper.showMessage('info', 'Aviso', 'Debe seleccionar uno o más registros para poder exportar');
      
      const columnsSelected = gridColumnApi.value.getAllDisplayedColumns().filter(column => columnKeys.value.includes(column.colId));
      
      gridApi.value.exportDataAsCsv({
        onlySelected: true,
        columnKeys: columnsSelected
      });
      
    }

    const onPageSizeChanged = () => {
      var value = document.getElementById('page-size').value;
      gridApi.value.paginationSetPageSize(Number(value));
    }
    
    //obtenemos los datos seleccionados de la tabla
    const getSelectedRows = () => { 
      const selectedNodes = gridApi.value.getSelectedNodes();
      const selectedData = selectedNodes.map( node => node.data);
      if(selectedData.length > 0){
        rowsSelected.value = selectedData;
        emit('rowsSelected', rowsSelected.value);
      }

      return selectedData;
    };

    const clickButtonDown = () => { 
      emit('clickButtonDown', true);
    };

    const changeFilter = (event) => {

      
      const rowsArray = props.rows;
      let newRowsArray = [];

      let fields = event.filter(row => row.value != "");
      
      if(fields.length==0){
        gridApi.value.setRowData(rowsArray);
        return;
      }
      
      emit('changeTableFilter', fields);

      //Se debe recorrer los filtros primero y luego el listado
      rowsArray.map(filterRow => {
        fields.map(row => {
          var str = filterRow[row.name].toString().toUpperCase();
          var st = row.value.toString().toUpperCase();
          
          if(row.type === 'date'){//Si es un valor de tipo fecha
            const valueType = new Date(st.split('-')).toString();
            st = moment(valueType).format("DD/MM/YYYY").toString();
            if(st == str){
              newRowsArray.push(filterRow);
            }
          }else if(str.indexOf(st) > -1){ // true
            newRowsArray.push(filterRow);
          }

        })
      });
 
      gridApi.value.setRowData(newRowsArray);
    }
    
    return {
      columnDefs: props.columns,
      rowData: props.rows,
      defaultColDef,
      efect,
      rowSelection,
      paginationPageSize,
      overlayLoadingTemplate,
      overlayNoRowsTemplate,
      paginationNumberFormatter,
      localeText,
      getRowClass,
      rowClass,
      onGridReady,
      onBtnExport,
      onPageSizeChanged,
      getSelectedRows,
      onSelectionChanged,
      changeFilter,
      clickButtonDown
    };
  },
};
</script>

<style scoped>
.ag-theme-alpine-custom{
    margin: 0 auto;
    border: 1px solid #FFC300;
    border-radius: 10px;
    overflow: hidden;
    color: blue;
    width: 98%;
    height: 500px;
}
.ag-header-row, .ag-row-even, .ag-row-odd{
  color: #174a84 !important;
}
.my-shaded-effect{
  background: #e5e5e5 !important;
}
</style>